import { useContext, useEffect } from 'react'
import { YearContext } from '../../../contexts/YearContext'

import styles from './YearPicker.module.scss'
import { DatesSearchContext, DatesSearchContextType } from '../../../contexts/DatesSearchContext'

type YearPickerProps = {
  years: (string | string[] | undefined)[],
  name: string,
}

export function YearPicker({ years, name }: YearPickerProps) {
  const { year, setYear } = useContext(YearContext)
  const { datesSearch } = useContext<DatesSearchContextType>(DatesSearchContext);

  // If there's a hotel stay added, set park ticket year to match
  useEffect(() => {
    if (datesSearch[0] !== undefined) {
      const travelYear:string = datesSearch[0].getFullYear().toString();
      if (travelYear !== '' && setYear) setYear(travelYear);
    }
    const currentYear: string = new Date().getFullYear().toString()
    if (currentYear !== '' && setYear) setYear(currentYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesSearch]);

  // No need to show a year picker if only one year is on sale:
  if (years.length < 2) return null

  const yearOptions = years.map(value => {
    const id = name + '-' + value
    const checked = value === year
    return (
      <span key={id}>
        <input id={id} type='radio' onChange={ ev => setYear && setYear(ev.target.value) } name={name} value={value} checked={checked} />
        <label htmlFor={id}>{ value }</label>
      </span>
    )
  })

  return (
    <div className={styles.YearPicker}>
      When are you going?
      <div className={styles.yearOptions}>
        {yearOptions}
      </div>
    </div>
  )
}
