import React, { useState, useContext } from "react"
import Image from "next/legacy/image"

import styles from './ListItemCalendarTicket.module.scss'

import { CartContext } from '../../../contexts/CartContext'

import { CartQuantityPicker } from '../../Cart/CartQuantityPicker/CartQuantityPicker'
import { ProductTicketType, ProductType } from '../../../types/ProductType'
import { AddToCartTicketType } from '../../../types/CartType'
import { CalendarTicketPopup } from './CalendarTicketPopup/CalendarTicketPopup'
import { BookingSiteOperated } from "../../Misc/BookingSiteOperated/BookingSiteOperated";

import { YearContext } from "../../../contexts/YearContext"

type ListItemCalendarTicketProps = {
  product: ProductType,
  isDatedTicket: boolean | any,
}

export function ListItemCalendarTicket({ product, isDatedTicket }: ListItemCalendarTicketProps ) {
  const [qty, setQty] = useState(0)
  const [adultQty, setAdultQty] = useState(0)
  const [childQty, setChildQty] = useState(0)
  const [popupOpen, setPopupOpen] = useState(false);
  const [dateAdultPrice, setDateAdultPrice] = useState('')
  const [dateChildPrice, setDateChildPrice] = useState('')

  const aboveText = "Park-to-Park"

  const { year } = useContext(YearContext)

  const adultTicketId = product.tickets.find(t => t.type === "Adult")?.ticket_id
  const childTicketId = product.tickets.find(t => t.type === "Child")?.ticket_id

  const adultPrice = product.tickets.find(t => t.ticket_id === adultTicketId)?.price_from ?? ''
  const childPrice = product.tickets.find(t => t.ticket_id === childTicketId)?.price_from ?? ''

  const ticket: ProductTicketType | undefined = !isDatedTicket ? product.tickets[0] : undefined
  const adultTicket = isDatedTicket ? product.tickets.find(t => t.ticket_id === adultTicketId) : undefined
  const childTicket = isDatedTicket ? product.tickets.find(t => t.ticket_id === childTicketId) : undefined

  const selectBtnText = 'Select';

  // @todo: Where should the logic for this go? Does this belong in the API?
  type LogoImg = { path: string, alt: string }
  let logos: Array<LogoImg> = []
  if (isDatedTicket) {
    logos.push({ path: '/park-logos/universal-studios.png', alt: 'Universal Studios' })
    logos.push({ path: '/park-logos/islands-of-adventure.png', alt: 'Islands of Adventure'})
    logos.push({ path: '/park-logos/volcano-bay.png', alt: 'Volcano Bay'})
  } else {
    if (product.title.includes('Universal Studios')) logos.push({ path: '/park-logos/universal-studios.png', alt: 'Universal Studios' })
    if (product.title.includes('Adventure')) logos.push({ path: '/park-logos/islands-of-adventure.png', alt: 'Islands of Adventure'})
  }
  return (
    <CartContext.Consumer>
      {({cartAPI, cart}) => {

        const addToBasketBtn = (dateId: string, callback?: Function) => {
          return (
            <button
              className={styles.btn}
              disabled={ ( qty < 1 && adultQty < 1 && childQty < 1 ) || cart?.loading }
              onClick={async () => {
                const items: Array<AddToCartTicketType> = []
                if (ticket) items.push({ ticketId: ticket.ticket_id, qty: qty, dateId })
                if (adultTicket) items.push({ ticketId: adultTicket.ticket_id, qty: adultQty, dateId })
                if (childTicket) items.push({ ticketId: childTicket.ticket_id, qty: childQty, dateId })
                if (items.length) await cartAPI?.add(items)
                if (callback) callback()
                setAdultQty(0)
                setChildQty(0)
                setQty(0)
              }}
            >ADD TO BASKET</button>
          )
        }

        const qtyPickerFromPrice = ticket ? <CartQuantityPicker ticketPrice={ticket.price_from} showTicketPrice={true} showTotalPrice={false} qty={qty} setQty={(qty: number) => setQty(qty)} loading={cart?.loading ?? false} leftText="per ticket from" leftSubText="All ages" /> : null
        const adultQtyPicker = adultTicket ? <CartQuantityPicker ticketPrice={ adultPrice } showTicketPrice={true} showTotalPrice={false} qty={adultQty} setQty={(qty: number) => setAdultQty(qty)} loading={cart?.loading ?? false} leftText="per ticket from" leftSubText={ adultTicket.type + ' ticket ' + adultTicket.type_description } numDays={ product.num_days } /> : null
        const childQtyPicker = childTicket ? <CartQuantityPicker ticketPrice={childPrice} showTicketPrice={true} showTotalPrice={false} qty={childQty} setQty={(qty: number) => setChildQty(qty)} loading={cart?.loading ?? false} leftText="per ticket from" leftSubText={childTicket.type + ' ticket ' + childTicket.type_description} numDays={product.num_days} /> : null
        const qtyPickerCalendar = ticket ? <CartQuantityPicker ticketPrice={ticket.price_from} showTicketPrice={false} showTotalPrice={false} qty={qty} setQty={setQty} loading={cart?.loading ?? false} leftText="All ages" leftSubText="" /> : null
        const adultQtyPickerCalendar = adultTicket ? <CartQuantityPicker ticketPrice={ dateAdultPrice } showTicketPrice={true} showTotalPrice={false} qty={adultQty} setQty={setAdultQty} loading={cart?.loading ?? false} leftText={ adultTicket.type + ' ticket:' } leftSubText={ adultTicket.type_description } numDays={ product.num_days } /> : null
        const childQtyPickerCalendar = childTicket ? <CartQuantityPicker ticketPrice={dateChildPrice} showTicketPrice={true} showTotalPrice={false} qty={childQty} setQty={setChildQty} loading={cart?.loading ?? false} leftText={childTicket.type + ' ticket:'} leftSubText={childTicket.type_description} numDays={product.num_days} /> : null

        return (
          <div className={ `${styles.listItemCalendarTicket} ${ product.internal.persuasion_ribbon ? styles.hasPersuasion : null }`}>
            <div className={styles.ticketProductFullWidth}>
              { product.internal.persuasion_ribbon ? <div className={ styles.persuasionRibbon }>{ product.internal.persuasion_ribbon }</div> : null }
              {isDatedTicket && <p className={ styles.textAbove }>{ aboveText }</p>}
              <h3>{ product.title }</h3>
              {!isDatedTicket && <p className={ styles.descriptionText }>{ product.desc_short }</p>}
              <div className={ styles.row }>
                <div className={ styles.logosHolder }>
                  <div className={ styles.logos }>
                  {
                    logos.map((logo, idx) => {
                      return (
                        <span key={logo.path}>
                          {idx > 0 ? <Image src={'/park-logos/plus.png'} width={33} height={76} alt='plus-logo'/> : null}
                          <Image priority src={logo.path} width={100} height={76} alt={logo.alt} />
                        </span>
                      )
                    })
                  }
                </div>
                { product.internal.persuasion_ribbon ? <p className={ styles.persuasionText }>{ product.internal.persuasion_message }</p> : null }
                </div>
                <div className={ styles.qtyPickers }>
                  { adultQtyPicker }
                  { childQtyPicker }
                  { qtyPickerFromPrice }
                </div>
              </div>
            </div>
            <div className={ styles.footer }>
              <div className={styles.footerDetails}>
                <CalendarTicketPopup popupOpen={popupOpen} onClose={() => setPopupOpen(false)} product={product} qtyPicker={qtyPickerCalendar} childQtyPicker={childQtyPickerCalendar} adultQtyPicker={adultQtyPickerCalendar} addToBasketBtn={addToBasketBtn} setPopupOpen={setPopupOpen} setDateAdultPrice={setDateAdultPrice} setDateChildPrice={setDateChildPrice} isDatedTicket={isDatedTicket}/>
                <p className={styles.detailsLink}><a className={styles.modalLink} onClick={() => setPopupOpen(true)}>View Details</a></p>
                <div className={styles.button}>
                  <p>Select a date to see updated pricing</p>
                  <button className={styles.btn} onClick={() => setPopupOpen(true)}>{selectBtnText}</button>
                </div>
              </div>
              <BookingSiteOperated placement="ticket" />
            </div>
          </div>
        )
      }}
    </CartContext.Consumer>
  )
}
